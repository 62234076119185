<template>
  <UiHeader
    header-layout="section-header"
    :header="props.header"
    class="text-primary"
  />

  <UiMenuSubpages
    v-if="menuItems?.length"
    variant="full"
    :menu="menuItems"
    class="hidden lg:flex"
    @click="setActiveTab"
  />
  <UiScrollWrapper
    v-if="menuItems?.length"
    class="flex lg:hidden"
  >
    <UiMenuSubpages
      variant="full"
      :menu="menuItems"
      @click="setActiveTab"
    />
  </UiScrollWrapper>
  <KeepAlive>
    <T3Renderer
      v-if="activeTabContent"
      :content="[activeTabContent]"
    />
  </KeepAlive>
</template>

<script lang="ts" setup>
import type { T3CeBaseProps, T3ContentElement } from '@t3headless/nuxt-typo3'
import UiMenuSubpages from '@ui/components/UiMenuSubpages/UiMenuSubpages.vue'
import UiScrollWrapper from '@ui/components/helpers/UiScrollWrapper/UiScrollWrapper.vue'
import type { MenuItem } from '@ui/components/UiMenuSubpages/UiMenuSubpages.types'
import T3Renderer from '../T3Renderer.vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  header?: string
  tabs: T3ContentElement<T3CeBaseProps>[]
}>()

const tabContent = computed(() => {
  return props.tabs?.map(tab => ({
    ...tab,
    content: {
      ...(tab.content ?? {}),
      header: '',
    },
  }))
})

const activeTab = ref(props.tabs?.[0]?.id)
const activeTabContent = ref(tabContent.value?.[0])

const menuItems = computed<MenuItem[]>(() => {
  return props.tabs?.map(tab => ({
    id: tab?.id ?? 0,
    title: tab?.content?.header || '',
    active: tab?.id === activeTab.value,
  }))
})

function setActiveTab(el: MenuItem) {
  activeTab.value = el.id
  activeTabContent.value = tabContent.value?.find(tab => tab.id === el.id)
}
</script>
